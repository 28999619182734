// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.customerCreateOrder .filtersGrid {
  grid-template-columns: 1fr 1fr;
}
.customerCreateOrder .ant-modal-footer button {
  width: 100% !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/UiComponents/CustomModal/CustomModal.less"],"names":[],"mappings":"AAEA;EAEI,8BAAA;AAFJ;AAAA;EAMM,sBAAA;AAHN","sourcesContent":["@import url(\"../../../assets/Styles/variables.less\");\n\n.customerCreateOrder {\n  .filtersGrid {\n    grid-template-columns: 1fr 1fr;\n  }\n  .ant-modal-footer {\n    button {\n      width: 100% !important;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
