// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.customInputNumberWrapper {
  height: 45px;
  display: inline-block;
  width: 100%;
}
.customInputNumberWrapper .ant-input-number,
.customInputNumberWrapper .ant-input-number-input-wrap {
  height: 100%;
  width: 100%;
}
.customInputNumberWrapper input {
  height: 100%;
  font-size: 14px !important;
  color: #000000 !important;
  font-family: "Poppins", sans-serif !important;
  font-weight: 400;
  line-height: 1 !important;
  letter-spacing: 0em;
}
.customInputNumberWrapper input::placeholder {
  color: #00000080;
  font-size: 14px;
  font-family: "Poppins", sans-serif !important;
  font-weight: 400;
  opacity: 1;
}
`, "",{"version":3,"sources":["webpack://./src/components/UiComponents/CustomInputNumber/CustomInputNumber.less"],"names":[],"mappings":"AAEA;EACE,YAAA;EACA,qBAAA;EACA,WAAA;AADF;AAFA;;EAMI,YAAA;EACA,WAAA;AAAJ;AAPA;EAWI,YAAA;EACA,0BAAA;EACA,yBAAA;EACA,6CAAA;EACA,gBAAA;EACA,yBAAA;EACA,mBAAA;AADJ;AAEI;EACE,gBAAA;EACA,eAAA;EACA,6CAAA;EACA,gBAAA;EACA,UAAA;AAAN","sourcesContent":["@import url(\"../../../assets/Styles/variables.less\");\n\n.customInputNumberWrapper {\n  height: 45px;\n  display: inline-block;\n  width: 100%;\n  .ant-input-number,\n  .ant-input-number-input-wrap {\n    height: 100%;\n    width: 100%;\n  }\n\n  input {\n    height: 100%;\n    font-size: 14px !important;\n    color: @black-color !important;\n    font-family: @primary-font-family !important;\n    font-weight: 400;\n    line-height: 1 !important;\n    letter-spacing: 0em;\n    &::placeholder {\n      color: @placeholder;\n      font-size: 14px;\n      font-family: @primary-font-family !important;\n      font-weight: 400;\n      opacity: 1;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
