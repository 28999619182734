import React, { memo } from "react";
import { Table } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { PAGE_SIZE_OPTIONS } from "utils/Constants/Constant";
import "./CustomTable.less";

const CustomTable = ({
  data,
  className = "",
  pagination,
  loading,
  listingCallback = () => {},
  ...props
}) => {
  let uniqueId = 0;

  const handleOnChange = (newPagination, filters, sorter) => {
    const { current, pageSize } = newPagination;
    listingCallback({ pageNo: current, pageSize, filters, sorter });
  };

  const currentPage = pagination.currentPage > 0 ? pagination.currentPage : 1;

  return (
    <div className="custom-table-grid-view-container">
      <Table
        dataSource={data}
        className={`${className} custom-table-container cardWithStyle`}
        pagination={
          pagination && {
            current: currentPage,
            pageSize: pagination.pageSize,
            total: pagination.total,
            showSizeChanger: false,
            pageSizeOptions: PAGE_SIZE_OPTIONS,
            onChange: handleOnChange,
            onShowSizeChange: handleOnChange,
          }
        }
        onChange={handleOnChange}
        rowKey={(record) => record?.id || `unique-${++uniqueId}`}
        loading={{
          spinning: loading ? loading : false,
          indicator: (
            <LoadingOutlined
              style={{
                fontSize: 40,
                color: "#C11B2F",
              }}
              spin
            />
          ),
        }}
        {...props}
      />
    </div>
  );
};

export default memo(CustomTable);
