import { InputNumber } from "antd";
import React, { memo } from "react";
import "./CustomInputNumber.less";

const CustomInputNumber = (props) => {
  return (
    <span className="customInputNumberWrapper">
      <InputNumber autoComplete="off" {...props} min={0} />
    </span>
  );
};

export default memo(CustomInputNumber);
