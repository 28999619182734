import React, { useContext, useEffect, useState } from "react";
import { Form, Modal, Space } from "antd";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import CustomRow from "components/UiComponents/CustomRow";
import CustomForm from "components/UiComponents/CustomForm";
import CustomModal from "components/UiComponents/CustomModal";
import CustomButton from "components/UiComponents/CustomButton";
import CustomCard from "components/CustomComponents/CustomCard";
import CustomColumn from "components/UiComponents/CustomColumn";
import { SERVICE_URL } from "utils/Constants/ServiceUrl.constants";
import CustomFormItem from "components/UiComponents/CustomFormItem";
import CustomGridView from "components/CustomComponents/CustomGridView";
import CustomAutoComplete from "components/UiComponents/CustomAutoComplete";
import { DISCOUNT_STATUS } from "utils/Constants/Constant";
import notificationService from "utils/Notification/Notification.service";
import { useFetchUser } from "hooks/useUserApi";
import { useDiscountAssign, useDiscountUnAssign } from "hooks/useDiscountApi";
import { PageTitleContext } from "PageTitleContext";
import CircleExclamaion from "assets/SvgFiles/CircleExclamaion";
import TrashIcon from "assets/SvgFiles/trashicon.svg";
import coinIcon from "assets/Images/coin.svg";
import userIcon from "assets/Images/user.svg";
import "./Discounts.less";

const DiscountDetails = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { confirm } = Modal;
  const { setPageTitle } = useContext(PageTitleContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchValue, setSearchValue] = useState(undefined);
  const [selectedUser, setSelectedUser] = useState();
  const discountData = location?.state;

  const { mutate: assignDiscount, isPending: assigningDiscount } =
    useDiscountAssign();

  const { mutate: unAssignDiscount, isPending: unAssigningDiscount } =
    useDiscountUnAssign();

  const { data: userList, isFetching } = useFetchUser({
    search: searchValue,
  });

  useEffect(() => {
    setPageTitle(t("discountDetails"));
  }, [setPageTitle]);

  const applyDiscount = (formData) => {
    let payLoad = formData;
    payLoad = {
      userId: selectedUser?.id,
      discountId: discountData?.id,
    };

    assignDiscount(payLoad, {
      onSuccess: async (response) => {
        notificationService.success(t("discountApplied"));
        Modal.destroyAll();
        setIsModalOpen(false);
        setSearchValue("");
        form.setFieldsValue({
          userId: "",
        });
      },
      onError: (error) => {
        notificationService.error(
          error?.response?.data?.message
            ? error?.response?.data?.message
            : t("errorInvitingCustomer")
        );
      },
    });
  };

  const handleDelete = (id) => {
    unAssignDiscount(
      {
        userId: id,
        discountId: discountData?.id,
      },
      {
        onSuccess: () => {
          notificationService.success(t("discountRemoved"));
        },
        onError: (error) => {
          notificationService.error(
            error?.response?.data?.message
              ? error?.response?.data?.message
              : t("errorRemovingDiscount")
          );
        },
      }
    );
  };

  const discountDeleteModal = (id) => {
    confirm({
      wrapClassName: "logOutModal",
      title: t("areYouSure"),
      width: "455px",
      centered: "true",
      closable: "true",
      destroyOnClose: "true",
      content: t("youWantToRemoveDiscount"),
      okText: t("yes"),
      cancelText: t("cancel"),
      icon: (
        <div className="icon-wrapper">
          <CircleExclamaion />
        </div>
      ),
      async onOk() {
        handleDelete(id);
      },
      async onCancel() {},
      footer: (_, { OkBtn, CancelBtn }) => (
        <>
          <OkBtn />
          <CancelBtn />
        </>
      ),
    });
  };

  const columns = [
    {
      title: t("id"),
      key: "id",
      dataIndex: "id",
    },
    {
      title: t("name"),
      key: "name",
      dataIndex: "name",
      className: "bold",
    },
    {
      title: t("email"),
      key: "email",
      dataIndex: "email",
    },

    {
      title: t("action"),
      key: "action",
      className: "action-col",
      render: (res, record) => (
        <Space>
          <CustomButton
            htmlType="default"
            className="action-btn deleteIcon"
            onClick={() => discountDeleteModal(res.id)}
          >
            <img src={TrashIcon} alt={t("icon")} />
          </CustomButton>
        </Space>
      ),
    },
  ];

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
    setSearchValue("");
    form.setFieldsValue({
      userId: "",
    });
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setSearchValue("");
    form.setFieldsValue({
      userId: "",
    });
  };

  const handleSearch = (value) => {
    setSearchValue(value);
  };

  const handleSelectUser = (value, option) => {
    setSelectedUser(option);
  };

  return (
    <div className="customerDetails">
      <section className="sectionOne">
        <div className="wrapper">
          <CustomRow gutter={[16, 16]}>
            <CustomColumn xs={24} sm={24} md={24} lg={8} xl={8}>
              <CustomCard
                heading={t("name")}
                text={discountData?.name}
                icon={userIcon}
              />
            </CustomColumn>

            <CustomColumn xs={24} sm={24} md={24} lg={8} xl={8}>
              <CustomCard
                heading={`${t("discount")} (%)`}
                text={`${discountData?.percentage}%`}
                icon={coinIcon}
              />
            </CustomColumn>
          </CustomRow>
        </div>
      </section>

      <section className="sectionTwo mt-5">
        <CustomRow gutter={[16, 16]}>
          <CustomColumn lg={24} md={24} sm={24} xs={24}>
            <CustomGridView
              columns={columns}
              listingApiRoute={`${SERVICE_URL.DISCOUNT.DISCOUNTED_USERS_API}/${discountData?.id}`}
              additionalParams={{
                discountId: discountData?.id,
              }}
              searchBar={false}
              loadingStates={false}
              titleButton={true}
              btnText={t("applyDiscount")}
              btnAction={() => {
                showModal();
              }}
              btnDisabled={discountData?.status === DISCOUNT_STATUS.INACTIVE}
            />
          </CustomColumn>
        </CustomRow>
      </section>

      <CustomModal
        title={`${t("selectUser")}`}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        width={"455px"}
        footer={null}
      >
        <CustomForm
          // initialValues={{ remember: true }}
          name="basic"
          form={form}
          onFinish={applyDiscount}
          className="gx-signin-form gx-form-row0"
        >
          <CustomRow gutter={[16, 16]}>
            <CustomColumn xs={24} sm={24} md={24} lg={24} xl={24}>
              <CustomFormItem
                name={"userId"}
                colon={false}
                rules={[
                  {
                    required: true,
                    message: t("userIdIsRequired"),
                  },
                ]}
              >
                <CustomAutoComplete
                  options={
                    userList?.data?.data &&
                    userList?.data?.data?.map((customer, index) => ({
                      value: customer?.name,
                      id: customer?.id,
                      key: `${customer.id}-${index}`,
                    }))
                  }
                  value={searchValue}
                  onChange={(value) => handleSearch(value)}
                  onSelect={handleSelectUser}
                  placeholder={t("searchAndAddAUser")}
                  allowClear={false}
                  loading={true}
                  notFoundContent={
                    !userList?.data?.data || searchValue === ""
                      ? t("searchTheUser")
                      : isFetching
                      ? t("searching")
                      : t("noUserFound")
                  }
                />
              </CustomFormItem>
            </CustomColumn>

            <CustomColumn xs={24} sm={24} md={24} lg={24} xl={24}>
              <div className="text-center">
                <CustomFormItem>
                  <CustomButton
                    type="primary"
                    className="btn"
                    htmlType="submit"
                    loading={assigningDiscount}
                  >
                    {t("apply")}
                  </CustomButton>
                </CustomFormItem>
              </div>
            </CustomColumn>
          </CustomRow>
        </CustomForm>
      </CustomModal>
    </div>
  );
};

export default DiscountDetails;
