import React, { memo } from "react";
import PropTypes from "prop-types";
import { Button } from "antd";
import "./CustomButton.less";

const CustomButton = (props) => {
  return (
    <Button
      {...props}
      className={`${
        props?.className ? props?.className : ""
      } gx-mb-0 btnStyling ${props?.white ? "white" : ""}`}
    >
      {props.children}
    </Button>
  );
};

CustomButton.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  white: PropTypes.bool,
};

export default memo(CustomButton);
