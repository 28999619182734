import { Input, Skeleton, Spin } from "antd";
import React, { memo } from "react";
import "./CustomInput.less";

const CustomInput = ({ isLoading, loading, ...props }) => {
  return (
    <span className="kl-custom-input-container">
      {isLoading ? (
        <Skeleton.Input active={true} size="large" />
      ) : (
        <Input autoComplete="off" {...props} />
      )}
    </span>
  );
};

export default memo(CustomInput);
