import axios from "axios";
import { ERROR } from "utils/Constants/Constant";
// import {unAuthenticatedRoutes} from "utils/Constants/routes.constant";

const baseUrl = process.env.REACT_APP_API_URL;

// Axios instance
const axiosInstance = axios.create({
  baseURL: baseUrl,
});

// Automatically attach JWT token to headers
axiosInstance.interceptors.request.use(
  (config) => {
    // Retrieve the token from localStorage
    const token = localStorage.getItem("jwtToken");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add a response interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (
      error.response &&
      error.response.status === ERROR.UNAUTHENTICATED_USER
    ) {
      localStorage.removeItem("jwtToken");
      localStorage.removeItem("isLoggedIn");
      localStorage.removeItem("orderResponse");
      localStorage.removeItem("cart");
      localStorage.removeItem("total");
      localStorage.removeItem("persist:root");
      window.location.href = `${process.env.REACT_APP_BASE_URL}`;
    }
    return Promise.reject(error);
  }
);

export const fetchAPI = (fetchParams) => {
  const { method, url, queryParams, data, signal, responseType } = fetchParams;
  return axiosInstance({
    method: method,
    url: url,
    data: data,
    params: queryParams,
    signal,
    responseType: responseType || "json", // Default to 'json', but allow overriding
  });
};
