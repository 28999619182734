import { Tooltip } from "antd";
import CustomTooltip from "components/UiComponents/CustomTooltip";
import { t } from "i18next";
import moment from "moment";
import { ENTITY_TYPE } from "utils/Constants/Constant";

// export const getValueOrPlaceholder = (res, property, placeholder = "-") => {
//   // Checking if property exists so it doesnt throw an error
//   if (!res || res[property] === undefined || res[property] === null) {
//     return placeholder;
//   }

//   if (property === "amount" && typeof res[property] === "number") {
//     return new Intl.NumberFormat("en-US", {
//       style: "currency",
//       currency: "USD",
//     }).format(res[property]);
//   }

//   return res[property];
// };

export const getValueOrPlaceholder = (
  res,
  property,
  placeholder = "-",
  maxLength = 30
) => {
  // Checking if property exists so it doesn't throw an error
  if (!res || res[property] === undefined || res[property] === null) {
    return placeholder;
  }

  if (property === "amount" && typeof res[property] === "number") {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(res[property]);
  }

  // Truncate long values and add ellipses
  const value = res[property];
  if (typeof value === "string" && value.length > maxLength) {
    return (
      <CustomTooltip title={value} overlayClassName={"red"}>{`${value.substring(
        0,
        maxLength
      )}...`}</CustomTooltip>
    );
  }

  return value;
};

// export const formatAmount = (amount) => {
//   if (typeof amount === "number" && amount > 10000) {
//     let amountNumber = new Intl.NumberFormat("en-US", {
//       style: "currency",
//       currency: "USD",
//     }).format(amount);
//     return (
//       <>
//         <CustomTooltip
//           title={amountNumber}
//           overlayClassName={"red"}
//         >{`${amountNumber.substring(0, 10)}...`}</CustomTooltip>
//       </>
//     );
//   }
//   return "-";
// };

export const formatAmount = (amount) => {
  if (typeof amount === "number") {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(amount);
  }
  return "-";
};

export const getLabelOrDefault = (lookupArray, value, propertyName) => {
  const foundObj = lookupArray.find((obj) => obj.value === value);
  return foundObj ? foundObj.label : `Unknown (${propertyName}: ${value})`;
};

export function getLabelById(valueArray, id) {
  if (!Array.isArray(valueArray)) {
    return "-";
  }

  const value = valueArray.find((value) => value.id === id);
  return value ? (value.title ? value.title : value.name) : "-";
}

export function generatePassword() {
  const chars =
    "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()";
  let password = "";
  for (let i = 0; i < 20; i++) {
    const randomIndex = Math.floor(Math.random() * chars.length);
    password += chars[randomIndex];
  }
  return password;
}

export const transformToOptions = (items, filterStatus = false) => {
  if (!items) return [];
  let filteredItems = items;
  if (filterStatus) {
    filteredItems = items.filter((item) => item.status !== 0);
  }
  return filteredItems.map((item) => ({
    label: item.name ? item.name : item.title,
    value: item.name ? item.name : item.title,
  }));
};

// To check if a URL is valid
const isValidURL = (url) => {
  try {
    new URL(url);
    return true;
  } catch (e) {
    return false;
  }
};

// To strip HTTPS protocol
const stripProtocol = (url) => {
  return url.replace(/^(https?:\/\/)?(.*?)(\/)?$/, "$2");
};

// To Render the domain with or without a link
export const renderDomain = (res) => {
  const domain = res?.domain;
  if (isValidURL(domain)) {
    return (
      <a href={domain} target="_blank" rel="noopener noreferrer">
        {stripProtocol(domain)}
      </a>
    );
  }
  return domain || "N/A";
};

export const getLabelByValue = (list, value) => {
  const entity = list.find((type) => type.value === value);
  return entity ? entity.label : "Unknown";
};

export const getEntityUrlByValue = (value) => {
  const entity = ENTITY_TYPE.find((item) => item.value === value);
  return entity ? entity.url : "";
};

// Color based on a string
export const stringToColor = (str) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  const color = Math.floor(
    Math.abs(((Math.sin(hash) * 10000) % 1) * 16777216)
  ).toString(16);
  return "#" + Array(6 - color.length + 1).join("0") + color;
};

export const getPaymentMethodName = (paymentMethodId, value) => {
  const method = value.find((method) => method.id === paymentMethodId);
  return method ? method.name : "-";
};

export const truncateText = (text, maxLength) => {
  if (text?.length <= maxLength) {
    return text;
  }
  return text?.substring(0, maxLength) + "...";
};

export const calculateDaysLeft = (dueDate) => {
  const currentDate = moment();
  const dueDateMoment = moment(dueDate);
  const daysLeft = dueDateMoment.diff(currentDate, "days");

  if (daysLeft > 0) {
    return `${daysLeft} ${t("daysLeft")}`;
  } else if (daysLeft === 0) {
    return t("dueToday");
  } else {
    return t("overdue");
  }
};

export function formatNumber(value) {
  if (typeof value !== "number" && !value) return "-";

  let numberString = typeof value === "number" ? value.toString() : value;

  numberString = numberString.replace(/,/g, "");

  if (isNaN(numberString)) return "";

  return parseFloat(numberString).toLocaleString();
}

export const joinArray = (data, separator = ", ") => {
  return Array.isArray(data) && data.length > 0 ? data.join(separator) : "-";
};

export const getDeliveryDescription = (days) => {
  if (days === 0) return t("sameBusinessDayDelivery");
  if (days === 1) return t("nextBusinessDayDelivery");
  if (days === 2) return t("withinTwoBusinessDays");
  if (days === 3) return t("withinThreeBusinessDays");
  if (days <= 5) return t("withinAWeek");
  return `${days} ${t("businessDays")}`;
};

export const getEstimatedDeliveryDate = (days) => {
  const currentDate = new Date();
  const deliveryDate = new Date(
    currentDate.setDate(currentDate.getDate() + days)
  );
  return deliveryDate.toLocaleDateString("en-US", {
    weekday: "long",
    month: "long",
    day: "numeric",
  });
};

export const calculateSubtotal = (items) => {
  if (!items || items?.length === 0) return 0;
  return items.reduce((total, item) => total + item.amount * item.quantity, 0);
};
