// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.searchbarWrapper {
  height: 45px;
}
.searchbarWrapper .ant-select,
.searchbarWrapper .ant-select-selector,
.searchbarWrapper .ant-input-group-wrapper,
.searchbarWrapper span {
  height: 100%;
}
.searchbarWrapper .ant-select input,
.searchbarWrapper .ant-select-selector input,
.searchbarWrapper .ant-input-group-wrapper input,
.searchbarWrapper span input,
.searchbarWrapper .ant-select button,
.searchbarWrapper .ant-select-selector button,
.searchbarWrapper .ant-input-group-wrapper button,
.searchbarWrapper span button {
  height: 100%;
}
.searchbarWrapper .ant-input-group-addon {
  display: none;
}
.searchbarWrapper input {
  color: #000000;
  font-size: 14px;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  line-height: 1;
  letter-spacing: 0em;
}
.searchbarWrapper input::placeholder {
  color: #00000080;
  font-size: 14px;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0em;
}
`, "",{"version":3,"sources":["webpack://./src/components/UiComponents/CustomSearchBar/CustomSearchBar.less"],"names":[],"mappings":"AAEA;EACE,YAAA;AADF;AAAA;;;;EAMI,YAAA;AAAJ;AANA;;;;;;;;EASM,YAAA;AAON;AAhBA;EAaI,aAAA;AAMJ;AAnBA;EAgBI,cAAA;EACA,eAAA;EACA,gBAAA;EACA,kCAAA;EACA,cAAA;EACA,mBAAA;AAMJ;AALI;EACE,gBAAA;EACA,eAAA;EACA,gBAAA;EACA,kCAAA;EACA,mBAAA;AAON","sourcesContent":["@import url(\"../../../assets/Styles/variables.less\");\n\n.searchbarWrapper {\n  height: 45px;\n  .ant-select,\n  .ant-select-selector,\n  .ant-input-group-wrapper,\n  span {\n    height: 100%;\n    input,\n    button {\n      height: 100%;\n    }\n  }\n  .ant-input-group-addon {\n    display: none;\n  }\n  input {\n    color: @black-color;\n    font-size: 14px;\n    font-weight: 400;\n    font-family: @primary-font-family;\n    line-height: 1;\n    letter-spacing: 0em;\n    &::placeholder {\n      color: @placeholder;\n      font-size: 14px;\n      font-weight: 400;\n      font-family: @primary-font-family;\n      letter-spacing: 0em;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
