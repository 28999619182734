import React, { memo } from "react";
import { AutoComplete } from "antd";
import "./CustomAutoComplete.less";

const CustomAutoComplete = (props) => {
  return (
    <span className="customAutoCompleteWrapper">
      <AutoComplete allowClear {...props} />
    </span>
  );
};

export default memo(CustomAutoComplete);
