import React, { useEffect, useState, useCallback } from "react";
import debounce from "lodash.debounce";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import CustomButton from "components/UiComponents/CustomButton";
import CustomColumn from "components/UiComponents/CustomColumn";
import CustomHeading from "components/UiComponents/CustomHeading";
import CustomRow from "components/UiComponents/CustomRow";
import CustomSelect from "components/UiComponents/CustomSelect";
import CustomSearchBar from "components/UiComponents/CustomSearchBar";
import CustomResult from "components/UiComponents/CustomResult";
import CustomTable from "../CustomTable";
import { useFetchTableListing } from "hooks/useTableListingApi";
import useMobileView from "hooks/useMobileView";
import {
  BILLING_FREQUENCY,
  CONTRACT_DURATION,
  CONTRACT_STATUS,
  DELIVERABLE,
  PAGE_SIZE,
  SERVICE_STATUS,
  START_CRITERIA,
  USER_ROLE,
} from "utils/Constants/Constant";
import "./CustomGridView.less";
import { Space } from "antd";

const CustomGridView = (props) => {
  const {
    listingApiRoute,
    searchCriteriaColumns,
    filters,
    searchBar,
    searchFilters,
    filterKeys,
    loadingStates,
    columns,
    titleButton,
    btnText,
    btnAction,
    btnIcon,
    btnLoading,
    btnDisabled,
    secondBtnText,
    secondBtnAction,
    secondBtnIcon,
    pageTitle,
    onDataListingChange,
    additionalParams,
    markAsDisabled,
    markAsOptions,
    markAsValue,
    handleMarkAsChange,
    onCheckboxChange,
    rowSelection,
    activeTabKey,
  } = props;

  const isMobileView = useMobileView();
  const { t } = useTranslation();

  const location = useLocation();
  const uniqueKey = location.pathname;

  // console.log(location, "location");
  // console.log(additionalParams, "additionalParams");
  // console.log(activeTabKey, "activeTabKey");

  // const [searchParams, setSearchParams] = useState({
  //   searchCriteria: {
  //     columns: searchCriteriaColumns,
  //   },
  //   ...additionalParams,
  //   direction: "desc",
  //   page: 1,
  //   limit: PAGE_SIZE,
  // });

  // Load initial searchParams from sessionStorage using the pathname
  const [searchParams, setSearchParams] = useState(() => {
    const savedState = JSON.parse(
      sessionStorage.getItem(
        `RouteData_${uniqueKey}_${activeTabKey ? activeTabKey : 1}`
      )
    );

    return (
      savedState || {
        searchCriteria: {
          columns: searchCriteriaColumns,
        },
        ...additionalParams,
        direction: "desc",
        page: 1,
        limit: PAGE_SIZE,
      }
    );
  });

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const [filteredData, setFilteredData] = useState([]);

  const {
    data: dataListing,
    isLoading: fetchingTableListing,
    isPlaceholderData,
    error,
  } = useFetchTableListing(searchParams, listingApiRoute);

  useEffect(() => {
    if (dataListing) {
      // By default, use all the data
      let filteredData = dataListing?.data?.data;

      // Apply filtering only if excludeStatus is defined
      if (additionalParams?.excludeStatus !== undefined) {
        filteredData = filteredData.filter(
          (item) => item.status !== additionalParams.excludeStatus
        );
      }

      // Set the filtered or original data
      setFilteredData(filteredData);

      setPagination((prev) => ({
        ...prev,
        // total: filteredData.length,
        total: dataListing?.data?.total,
        currentPage: searchParams.page,
      }));

      // Pass the filtered data to the parent component if needed
      onDataListingChange?.({
        ...dataListing,
        data: { ...dataListing.data, data: filteredData },
      });
    }
  }, [
    dataListing,
    searchParams.page,
    additionalParams?.excludeStatus,
    onDataListingChange,
  ]);

  // const [pagination, setPagination] = useState({
  //   currentPage: 1,
  //   pageSize: PAGE_SIZE,
  //   total: 0,
  //   showSizeChanger: false,
  //   showQuickJumper: false,
  // });

  // Automatically apply saved sorting and filters to columns
  const enrichedColumns = columns.map((col) => {
    const sortOrder =
      searchParams.column === col.key
        ? searchParams.direction === "asc"
          ? "ascend"
          : "descend"
        : null;
    // const filteredValue =
    //   col.key === "status" && searchParams?.status
    //     ? [searchParams.status]
    //     : null;

    const filteredValue = (() => {
      if (col.key === "status" && searchParams?.status) {
        return [searchParams.status];
      }
      if (col.key === "category" && searchParams?.category) {
        return [searchParams.category];
      }
      return null;
    })();

    return {
      ...col,
      sortOrder,
      filteredValue,
    };
  });

  const [pagination, setPagination] = useState({
    currentPage: searchParams.page || 1,
    pageSize: searchParams.limit || PAGE_SIZE,
    total: 0,
    showSizeChanger: false,
    showQuickJumper: false,
  });

  const FILTER_OPTIONS = {
    role: USER_ROLE,
    status: SERVICE_STATUS,
    ContractStatus: CONTRACT_STATUS,
    duration: CONTRACT_DURATION,
    deliverable: DELIVERABLE,
    startCriteria: START_CRITERIA,
    billingFrequency: BILLING_FREQUENCY,
  };

  const handleSearch = (searchTerm) => {
    setSearchParams((prevParams) => ({
      ...prevParams,
      // FOR FUTURE IF WE NEED SEARCH CRITERIA

      // searchCriteria: {
      //   ...prevParams.searchCriteria,
      //   term: searchTerm,
      // },
      search: searchTerm,
      page: 1,
    }));
  };

  const debouncedHandleSearch = useCallback(debounce(handleSearch, 600), []);

  // Save searchParams and pagination state to sessionStorage whenever they change
  useEffect(() => {
    sessionStorage.setItem(
      `RouteData_${uniqueKey}_${activeTabKey ? activeTabKey : 1}`,
      JSON.stringify(searchParams)
    );
  }, [searchParams, uniqueKey]);

  useEffect(() => {
    sessionStorage.setItem(
      `RoutePagination_${uniqueKey}_${activeTabKey ? activeTabKey : 1}`,
      JSON.stringify(pagination)
    );
  }, [pagination, uniqueKey]);

  const handleTableChange = (newPagination, filters, sorter) => {
    // Sorting direction: convert 'ascend' to 'asc' and 'descend' to 'dsc'
    const direction =
      sorter?.order === "ascend"
        ? "asc"
        : sorter?.order === "descend"
        ? "desc"
        : undefined;

    // Get the column key
    const column = sorter?.columnKey || undefined;

    // Join filter status values if they exist
    const status = filters?.status?.join(",");

    const category = filters?.category ? filters.category.join(",") : undefined;

    setSearchParams((prev) => {
      let updatedParams = {
        ...prev,
        page: newPagination.current,
        limit: newPagination.pageSize,
        status,
      };

      // Only add column and direction if sorting is applied
      if (direction && column) {
        updatedParams = {
          ...updatedParams,
          direction,
          column,
        };
      } else {
        // Remove column and direction if sorting is canceled
        delete updatedParams.column;
      }

      // Only add category if the filter exists
      if (category) {
        updatedParams = {
          ...updatedParams,
          category,
        };
      } else {
        // Remove category if no filter is applied
        delete updatedParams.category;
      }

      return updatedParams;
    });
  };

  const handleFilterChange = (key, value) => {
    setSearchParams((prevParams) => ({
      ...prevParams,
      filterCriteria: {
        ...prevParams.filterCriteria,
        [key]: value,
      },
      page: 1,
    }));
  };

  // const contentView = isMobileView ? (
  //   <CustomTableCard
  //     data={filteredData}
  //     columns={columns}
  //     loading={fetchingTableListing || isPlaceholderData || loadingStates}
  //     onCheckboxChange={onCheckboxChange}
  //     pagination={{
  //       current: pagination.currentPage,
  //       pageSize: pagination.pageSize,
  //       total: pagination.total,
  //     }}
  //     onChangePage={handleTableChange}
  //   />
  // ) : (
  //   <CustomTable
  //     bordered
  //     data={filteredData}
  //     // columns={columns}
  //     columns={enrichedColumns}
  //     loading={fetchingTableListing || isPlaceholderData || loadingStates}
  //     onChange={handleTableChange}
  //     pagination={{
  //       ...pagination,
  //       current: pagination.currentPage,
  //     }}
  //     rowSelection={rowSelection}
  //     scroll={{ x: "max-content" }}
  //   />
  // );

  const contentView = (
    <CustomTable
      bordered
      data={filteredData}
      // columns={columns}
      columns={enrichedColumns}
      loading={fetchingTableListing || isPlaceholderData || loadingStates}
      onChange={handleTableChange}
      pagination={{
        ...pagination,
        current: pagination.currentPage,
      }}
      rowSelection={rowSelection}
      scroll={{ x: "max-content" }}
    />
  );

  if (error)
    return (
      <CustomResult
        status="warning"
        title={t("somethingWentWrong")}
        subTitle={`${t("anErrorOccurred")}: ${error.message}`}
      />
    );

  return (
    <>
      {/* FILTERS & SEARCH FUNCTIONALITIES */}

      <div className="topHeader top-row">
        <CustomRow>
          <CustomColumn
            xs={24}
            sm={24}
            md={24}
            lg={12}
            xl={12}
            className="search-filter"
          >
            {pageTitle && (
              <CustomHeading className="title">{pageTitle}</CustomHeading>
            )}
          </CustomColumn>

          <CustomColumn
            className="topheader-btn"
            xs={24}
            sm={24}
            md={24}
            lg={12}
            xl={12}
          >
            <div className="right-header">
              <Space>
                {(searchFilters || searchBar || filters) && (
                  <div className="searchFilterWrapper">
                    {filterKeys && <h4>{t("filter")}</h4>}

                    {filterKeys &&
                      filterKeys.map(({ key, placeholder }) => {
                        const filterOptions = FILTER_OPTIONS[key];
                        if (!filterOptions) {
                          return null;
                        }

                        return (
                          <CustomSelect
                            key={key}
                            size="large"
                            placeholder={
                              placeholder ? placeholder : t("select")
                            }
                            popupMatchSelectWidth={true}
                            onChange={(value) => handleFilterChange(key, value)}
                            options={filterOptions.map((option) => ({
                              label: option.label,
                              value: option.value,
                            }))}
                          />
                        );
                      })}

                    {markAsOptions && (
                      <CustomSelect
                        size="large"
                        placeholder={t("markAs")}
                        popupMatchSelectWidth={true}
                        disabled={markAsDisabled}
                        value={markAsValue}
                        onChange={(e) => handleMarkAsChange(e)}
                        options={markAsOptions.map((option) => ({
                          label: option.label,
                          value: option.value,
                        }))}
                      />
                    )}

                    {searchBar && (
                      <CustomSearchBar
                        onSearch={debouncedHandleSearch}
                        defaultValue={searchParams.search || ""}
                      />
                    )}
                  </div>
                )}
                <Space className="btnSpace">
                  {secondBtnText && (
                    <CustomButton
                      htmlType="default"
                      // className="deafult-btn"
                      icon={secondBtnIcon}
                      onClick={secondBtnAction}
                    >
                      {secondBtnText}
                    </CustomButton>
                  )}

                  {titleButton && (
                    <CustomButton
                      htmlType="default"
                      className="deafult-btn"
                      icon={btnIcon}
                      onClick={btnAction}
                      disabled={btnDisabled}
                      loading={btnLoading}
                    >
                      {btnText}
                    </CustomButton>
                  )}
                </Space>
              </Space>
            </div>
          </CustomColumn>
        </CustomRow>
      </div>

      {/* ONLY TABLE COMPONENT */}
      <div className="custom-table-grid-view-container">{contentView}</div>
    </>
  );
};

export default CustomGridView;
