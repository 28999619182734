import { useState, useEffect } from "react";

const useMobileView = () => {
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 992);

  useEffect(() => {
    const updateView = () => {
      setIsMobileView(window.innerWidth < 992);
    };

    window.addEventListener("resize", updateView);
    return () => {
      window.removeEventListener("resize", updateView);
    };
  }, []);

  return isMobileView;
};

export default useMobileView;
