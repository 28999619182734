import React from "react";
import CustomSkeleton from "components/UiComponents/CustomSkeleton";
import "./CustomCard.less";

const CustomCard = ({
  icon,
  heading,
  text,
  loading,
  imgHeight,
  imgWidth,
  subText,
}) => {
  return (
    <div className="customCardWrapper">
      {loading ? (
        <CustomSkeleton length={1} type="card" />
      ) : (
        <div className="cont">
          <div className="icon">
            {icon && (
              <img
                src={icon}
                alt="icon"
                height={imgHeight ? imgHeight : 16}
                width={imgWidth ? imgWidth : 16}
              />
            )}
          </div>
          <div className="cardText">
            <h2>{heading}</h2>
            <h4>{text}</h4>
            {subText}
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomCard;
