import React from "react";
import { Link } from "react-router-dom";
import CustomSkeleton from "components/UiComponents/CustomSkeleton";
import "./CustomCard.less";

const CustomCard = ({
  icon,
  heading,
  text,
  link,
  linkState,
  loading,
  imgHeight,
  imgWidth,
  subText,
  anchor,
}) => {
  return (
    <div className="customCardWrapper">
      {loading ? (
        <CustomSkeleton length={1} type="card" />
      ) : (
        <div className="cont">
          <div className="icon">
            {icon && (
              <img
                src={icon}
                alt="icon"
                height={imgHeight ? imgHeight : 16}
                width={imgWidth ? imgWidth : 16}
              />
            )}
          </div>
          <div className="cardText">
            <h2>{heading}</h2>
            {link ? (
              <Link to={link} state={linkState}>
                <h4>{text}</h4>
              </Link>
            ) : anchor ? (
              <Link to={`mailto:${text}`}>{text}</Link>
            ) : (
              <h4>{text}</h4>
              // <h4 dangerouslySetInnerHTML={{ __html: text }} />
            )}
            {subText}
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomCard;
