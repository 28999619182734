export const APP_NAME = "JFA";

export const DATE_FORMAT = "MMM DD, YYYY";
export const DATE_FORMAT_MONTH = "MMM YYYY";

export const DATE_TIME_FORMAT = "MMM DD, YYYY / HH:mm";

export const MONTH_FORMAT = "MM-YYYY";
export const YEAR_FORMAT = "YYYY";

export const MAX_FILE_SIZE_MB = 5;

export const PAGE_SIZE = 10;
export const PER_PAGE_LIMIT = 100;

export const ZIPCODE_LIMIT = 15;

export const PAGE_SIZE_OPTIONS = ["10", "20", "50", "100"];

export const FREE_SHIPPING_AMOUNT = 500;

export const BREADCRUMB_SEPARATOR = "-";

export const NUMBER_PATTERN = {
  PATTERN: /^[-+]?[0-9]+\.[0-9]+$/,
};

export const PRODUCT_CATALOG_FILTER = {
  YEAR: 1,
  MAKE: 2,
  MODEL: 3,
  SUBMODEL: 4,
  CATEGORY: 5,
};

export const PHONE_MASK = [
  "(",
  /[1-9]/,
  /\d/,
  /\d/,
  ")",
  " ",
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

export const PAYMENT_METHODS = [
  {
    id: 1,
    name: "Credit Card",
    value: "credit-card",
    description: "Pay immediately using your credit card.",
  },
  {
    id: 2,
    name: "Net 30",
    value: "net-30",
    description: "30-day credit period before payment is due.",
  },
];

export const INVOICE_STATUS = [
  {
    label: "Completed",
    text: "Completed",
    value: 5,
    className: "statusCompleted",
  },
  {
    label: "Failed",
    text: "Failed",
    value: 4,
    className: "statusFailed",
  },
  {
    label: "Hold",
    text: "Hold",
    value: 3,
    className: "statusHold",
  },

  {
    label: "Paid",
    text: "Paid",
    value: 1,
    className: "statusPaid",
  },
  {
    label: "Pending",
    text: "Pending",
    value: 2,
    className: "statusPending",
  },
];

export const USER_TYPE = {
  ADMIN: 1,
  CUSTOMER: 2,
};
export const USER_STATUS = {
  INVITED: 2,
  ACTIVE: 1,
  INACTIVE: 0,
};

export const USER_STATUS_ARRAY = [
  {
    label: "Invited",
    value: 2,
    className: "statusInvited",
  },
  {
    label: "Active",
    value: 1,
    className: "statusActive",
  },
  {
    label: "Inactive",
    value: 0,
    className: "statusInactive",
  },
];

export const TOKEN_REASON = {
  FORGOT_PASSWORD: 1,
  RESET_PASSWORD: 2,
  VERIFICATION: 3,
  CHANGE_PASSWORD: 4,
};
export const MEDIA_TYPE = {
  IMAGE: 1,
  VIDEO: 2,
  DOCUMENT: 3,
  ARCHIVE: 4,
  OTHER: 5,
};

export const ORDER_STATUS = [
  {
    label: "Completed",
    text: "Completed",
    value: 2,
    className: "statusCompleted",
  },
  {
    label: "Draft",
    text: "Draft",
    value: 5,
    className: "statusDraft",
  },
  {
    label: "Failed",
    text: "Failed",
    value: 4,
    className: "statusFailed",
  },

  {
    label: "Pending",
    text: "Pending",
    value: 1,
    className: "statusPending",
  },
  {
    label: "Picked Up",
    text: "Picked Up",
    value: 6,
    className: "statusCompleted",
  },

  {
    label: "Shipped",
    text: "Shipped",
    value: 3,
    className: "statusShipped",
  },
];

export const CHARTDATA = {
  MONTHS: [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ],
  DAYS: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
  // COLORS: ["#ED495D ", "#C11B2F"],
  COLORS: ["#ee9c22 ", "#39a84a", "#2196f3", "#e64a19", "#edf2f7"],

  DATA: {
    PAYMENTS: [
      "0",
      "5",
      "10",
      "15",
      "20",
      "25",
      "30",
      "35",
      "40",
      "45",
      "50",
      "55",
      "60",
    ],
  },
};

export const ERROR = {
  UNAUTHENTICATED_USER: 401,
  FORBIDDEN_REQUEST: 403,
  NOT_FOUND: 404,
  BAD_REQUEST: 400,
  EXIST: 417,
  TOO_MANY_REQUEST: 429,
};

export const SERVICE_STATUS = [
  { label: "Active", value: 1, color: "#FF9800" },
  { label: "InActive", value: 0, color: "#9E9E9E" },
];

export const CONTRACT_STATUS = [
  { label: "Draft", value: 0, color: "#9E9E9E" },
  { label: "Sent", value: 10, color: "#2196F3" },
  { label: "Accepted", value: 11, color: "#4CAF50" },
  { label: "Active", value: 1, color: "#FF9800" },
  { label: "Cancelled", value: 12, color: "#E64A19" },
  { label: "Completed", value: 20, color: "#9C27B0" },
];

export const SERVICE_TYPE = [
  { label: "Purchasable Service", value: "Purchasable" },
  { label: "Nonpurchasable Service", value: "Nonpurchasable" },
  { label: "Custom", value: "Custom" },
];
export const DELIVERABLE = [
  { label: "None", value: 0 },
  { label: "Report", value: 1 },
];

export const USER_ROLE = [
  { label: "Admin", value: 1 },
  { label: "Account Manager", value: 2 },
  { label: "Project Manager", value: 3 },
];

export const SORT = [
  { label: "Ascending", value: "ASC" },
  { label: "Descending", value: "DESC" },
];

export const CONTRACT_DURATION = [
  { label: "1 Month", value: 1 },
  { label: "Quarter Year (3 Months)", value: 3 },
  { label: "Half Year (6 Months)", value: 6 },
  { label: "1 Year (12 Months)", value: 12 },
  { label: "2 Years (24 Months)", value: 24 },
  { label: "3 Years (36 Months)", value: 36 },
  { label: "Custom", value: 0 },
];
export const BILLING_FREQUENCY = [
  { label: "One Time", value: 0 },
  { label: "Monthly", value: 1 },
  { label: "Quarterly", value: 3 },
  { label: "Half Yearly", value: 6 },
  { label: "Yearly", value: 12 },
];

export const START_CRITERIA = [
  { label: "Fixed Date", value: 1 },
  { label: "Upon Accepting Contract", value: 2 },
  { label: "Upon First Payment", value: 3 },
];

export const ENTITY_TYPE = [{ label: "User", value: 10, url: "user" }];

export const DRIVE_TYPE = [
  { label: "All Wheel", value: 2 },
  { label: "Four Wheel", value: 1 },
  { label: "Front Wheel", value: 3 },
  { label: "Rare Wheel", value: 4 },
];

export const ORDER_TYPE = [
  { label: "Delivery", value: 1 },
  { label: "Pickup", value: 2 },
  { label: "Shipment By Own Company", value: 3 },
];

export const POSTAL_SERVICE = [
  { label: "USPS", value: 1 },
  { label: "UPS", value: 2 },
  { label: "Four Wheel", value: 0 },
  { label: "Two Wheel", value: 1 },
];

export const DISCOUNT_STATUS = {
  ACTIVE: 1,
  INACTIVE: 0,
};

export const DISCOUNT_STATUS_DROPDOWN = [
  { label: "Active", value: 1 },
  { label: "InActive", value: 0 },
];
