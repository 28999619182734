// CONTROLLERS
const authController = "/auth";

// ROUTES
const meRoute = "/me";
const email = "/email";
const image = "/image";
const assign = "/assign";
const values = "/values";
const filter = "/filter";
const update = "/update";
const upload = "/upload";
const status = "/status";
const userRoute = "/user";
const details = "/details";
const billing = "/billing";
const payment = "/payment";
const statsRoute = "/stats";
const orderRoute = "/order";
const unassign = "/unassign";
const loginRoute = "/login";
const download = "/download";
const logoutRoute = "/logout";
const reportRoute = "/reports";
const otpRoute = "/verify-otp";
const signupRoute = "/register";
const invoiceRoute = "/invoice";
const productRoute = "/product";
const revenueReport = "/revenue";
const discountRoute = "/discount";
const categories = "/categories";
const invitation = "/invitation";
const businessRoute = "/business";
const productReport = "/products";
const customerRoute = "/customer";
const ordersReportRoute = "/orders";
const cardDetails = "/card-details";
const customerReport = "/customers";
const refreshTokenRoute = "/refresh";
const attachmentRoute = "/attachment";
const discountedUsersRoute = "/users";
const forgotRoute = "/forgot-password";
const resetPwdRoute = "/reset-password";
const processOrders = "/process-orders";
const salesReportRoute = "/sales-report";
const verifyEmailRoute = "/verify-email/";
const shippingRoute = "/shipping-address";
const shippingRatesRoute = "/shipping-rates";
const warehouseRoute = "/warehouse";
const paymentSucceeded = "/payment-succeeded";
const changePasswordRoute = "/change-password";
const verifyInvitationRoute = "/verify-invitation";
const verifyProductsQuanityRoute = "/verify-products";
const addTrackingNumberRoute = "/add-tracking-number";
const resendVerificationRoute = "/resend-verification";
const geocodeRoute = "https://maps.googleapis.com/maps/api/geocode/json";

export const SERVICE_URL = {
  AUTH: {
    ME: `${authController}${meRoute}`,
    OTP: `${authController}${otpRoute}`,
    LOGIN: `${authController}${loginRoute}`,
    FORGOT: `${authController}${forgotRoute}`,
    LOGOUT: `${authController}${logoutRoute}`,
    SIGN_UP: `${authController}${signupRoute}`,
    RESET_PASSWORD: `${authController}${resetPwdRoute}`,
    VERIFY_EMAIL: `${authController}${verifyEmailRoute}`,
    REFRESH_TOKEN: `${authController}${refreshTokenRoute}`,
    CHANGE_PASSWORD: `${authController}${changePasswordRoute}`,
    VERIFY_INVITATION: `${authController}${verifyInvitationRoute}`,
  },

  ATTACHMENT: {
    ATTACHMENT_API: `${attachmentRoute}`,
  },
  BILLING: {
    CARD_REMOVE: `${billing}`,
    PAYMENT_API: `${billing}${payment}`,
    CARD_UPDATE: `${billing}${cardDetails}`,
    BILLING_DETAILS_API: `${billing}${details}`,
    PAYMENT_SUCCEEDED_API: `${billing}${paymentSucceeded}`,
  },
  BUSINESS: {
    BUSINESS_API: `${businessRoute}`,
  },
  CUSTOMER: {
    CUSTOMER_API: `${customerRoute}`,
  },
  INVOICE: {
    INVOICE_API: `${invoiceRoute}`,
    INVOICE_DOWNLOAD: `${download}`,
    INVOICE_EMAIL: `${invoiceRoute}${email}`,
  },
  ORDER: {
    ORDER_API: `${orderRoute}`,
    STATUS: `${orderRoute}${update}${status}`,
    PROCESS_ORDERS: `${orderRoute}${processOrders}`,
    SHIPPING_RATES: `${orderRoute}${shippingRatesRoute}`,
    TRACKING_NUMBER: `${orderRoute}${addTrackingNumberRoute}`,
    VERIFY_PRODUCTS_QUANTITY: `${orderRoute}${verifyProductsQuanityRoute}`,
  },
  REPORT: {
    REPORT_API: `${reportRoute}`,
    STATS: `${reportRoute}${statsRoute}`,
    DOWNLOAD_REPORT: `${reportRoute}${download}`,
    PRODUCT_REPORT: `${reportRoute}${productReport}`,
    REVENUE_REPORT: `${reportRoute}${revenueReport}`,
    SALES_REPORT: `${reportRoute}${salesReportRoute}`,
    CUSTOMER_REPORT: `${reportRoute}${customerReport}`,
    ORDERS_REPORT: `${reportRoute}${ordersReportRoute}`,
  },
  PRODUCT: {
    PRODUCT_API: `${productRoute}`,
    IMAGE_UPLOAD: `${productRoute}${image}`,
    PRODUCT_UPLOAD: `${productRoute}${upload}`,
    PRODUCT_FILTER: `${productRoute}${filter}`,
    PRODUCT_CATEGORY: `${productRoute}${categories}${values}`,
  },
  SHIPPING: {
    SHIPPING_API: `${shippingRoute}`,
  },
  WAREHOUSE: {
    WAREHOUSE_API: `${warehouseRoute}`,
  },
  USER: {
    USER_API: `${userRoute}`,
    USER_INVITATION_API: `${userRoute}${invitation}`,
    USER_RESEND_VERIFICATION_API: `${userRoute}${resendVerificationRoute}`,
  },
  DISCOUNT: {
    DISCOUNT_API: `${discountRoute}`,
    DISCOUNTED_USERS_API: `${discountRoute}${discountedUsersRoute}`,
    DISCOUNT_ASSIGN_API: `${discountRoute}${assign}`,
    DISCOUNT_UNASSIGN_API: `${discountRoute}${unassign}`,
  },
  LOCATION: {
    GEOCODE_API: `${geocodeRoute}`,
  },
};
