export const QUERY_KEYS = {
  USER: "User",
  ORDER: "Order",
  REPORT: "Report",
  INVOICE: "Invoice",
  PRODUCT: "Product",
  DISCOUNT: "Discount",
  CUSTOMER: "Customer",
  BUSINESS: "Business",
  SHIPPING: "Shipping",
  WAREHOUSE: "Warehouse",
  TABLE_LISTING: "Listing",
  PRODUCT_ADD: "Product Add",
  REPORT_SALES: "Report Sales",
  REPORT_STATS: "Report Stats",
  REPORT_ORDER: "Report Orders",
  PRODUCT_IMAGE: "Product Image",
  SHIPPING_RATES: "Shipping Rates",
  BILLING_PAYMENT: "Billing Payment",
  BILLING_DETAILS: "Billing Details",
  CHANGE_PASSWORD: "Change Password",
  ORDER_TRACKING_NUMBER: "Order Tracking Number",
};
