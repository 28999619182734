import React from "react";
import { Modal } from "antd";
import "./CustomModal.less";

function CustomModal(props, children) {
  return (
    <div className="gl-container-header">
      <Modal centered {...props}>
        {props.children}
      </Modal>
    </div>
  );
}

export default CustomModal;
