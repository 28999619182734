import React, { memo } from "react";
import { Switch } from "antd";
import "./CustomSwitch.less";

const CustomSwitch = ({ checked, activeLabel, inactiveLabel, ...props }) => {
  return (
    <div className="customerSwitchWrapper">
      <span className="label">{checked ? activeLabel : inactiveLabel}</span>
      <Switch
        checked={checked}
        {...props}
        className={` ${
          props?.className ? props?.className : ""
        } gx-mb-0 switchStyling`}
      />
    </div>
  );
};

export default memo(CustomSwitch);
