import { Divider } from "antd";
import React, { memo } from "react";
import "./CustomDivider.less";

function CustomDivider(props) {
  return (
    <Divider {...props} className={`custom-divider ${props.className}`}>
      {props.children}
    </Divider>
  );
}

export default memo(CustomDivider);
