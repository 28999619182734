import React from "react";
import CustomRow from "../CustomRow";
import CustomColumn from "../CustomColumn";
import { Card, Skeleton } from "antd";

const CustomSkeleton = ({ length, type = "default", size = "default" }) => {
  const getSkeletonType = (type) => {
    switch (type) {
      case "card":
        return <Skeleton active avatar paragraph={{ rows: 4 }} />;
      case "list":
        return <Skeleton active title paragraph={{ rows: 2 }} />;
      case "image":
        return <Skeleton.Image active />;
      case "para":
        return <Skeleton active paragraph={{ rows: 0 }} />;
      default:
        return <Skeleton active />;
    }
  };

  const getColumnSpan = (type) => {
    switch (type) {
      case "list":
        return 24; // Full width for vertical list
      case "card":
      case "para": // Full width for para skeleton
        return 24;
      case "image":
        return 6; // Smaller column size for cards or images
      default:
        return 6; // Default column size
    }
  };

  return (
    <CustomRow gutter={[16, 16]}>
      {Array.from({ length }).map((_, index) => (
        <CustomColumn span={getColumnSpan(type)} key={index}>
          {type === "para" ? (
            getSkeletonType(type) // Exclude Card for para
          ) : (
            <Card>{getSkeletonType(type)}</Card>
          )}
        </CustomColumn>
      ))}
    </CustomRow>
  );
};

export default CustomSkeleton;
