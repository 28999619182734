// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.customerWrapper .topHeader h5 {
  color: #040f0f;
  font-weight: 600;
  font-size: 14px;
}
.customerWrapper .topHeader .right-header .aside {
  gap: 10px;
}
.customerWrapper .action-btn {
  color: #606060 !important;
}
.customerWrapper .action-btn:hover {
  color: #c11b2f !important;
  opacity: 1 !important;
}
.customerDetails .sectionOne button svg {
  stroke: #fff;
}
.customerDetails .sectionTwo .customTabWrapper .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-tab {
  padding: 25px;
}
.customerDetails .sectionTwo .customTabWrapper .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #c11b2f;
  font-size: 14px !important;
}
.customerDetails .sectionTwo .customTabWrapper .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-tab .ant-tabs-tab-btn {
  color: #a3a3a3;
  font-size: 14px !important;
}
.custom-table-grid-view-container table thead tr .ant-table-cell {
  white-space: nowrap;
}
.custom-table-grid-view-container table tbody tr td {
  white-space: nowrap;
}
`, "",{"version":3,"sources":["webpack://./src/screens/Discounts/Discounts.less"],"names":[],"mappings":"AAEA;EAGM,cAAA;EACA,gBAAA;EACA,eAAA;AAHN;AAFA;EASQ,SAAA;AAJR;AALA;EAcI,yBAAA;AANJ;AAOI;EACE,yBAAA;EACA,qBAAA;AALN;AAUA;EAIQ,YAAA;AAXR;AAOA;EAcc,aAAA;AAlBd;AAmBc;EAEI,cAAA;EACA,0BAAA;AAlBlB;AAAA;EAsBgB,cAAA;EACA,0BAAA;AAnBhB;AA6BA;EAKU,mBAAA;AA/BV;AA0BA;EAYU,mBAAA;AAnCV","sourcesContent":["@import url(../../assets/Styles/variables.less);\n\n.customerWrapper {\n  .topHeader {\n    h5 {\n      color: @black-link;\n      font-weight: 600;\n      font-size: 14px;\n    }\n    .right-header {\n      .aside {\n        gap: 10px;\n      }\n    }\n  }\n  .action-btn {\n    color: @grey-input !important;\n    &:hover {\n      color: @primary-color !important;\n      opacity: 1 !important;\n    }\n  }\n}\n\n.customerDetails {\n  .sectionOne {\n    button {\n      svg {\n        stroke: #fff;\n      }\n    }\n  }\n  .sectionTwo {\n    .customTabWrapper {\n      .ant-tabs-nav {\n        .ant-tabs-nav-wrap {\n          .ant-tabs-nav-list {\n            .ant-tabs-tab {\n              padding: 25px;\n              &.ant-tabs-tab-active {\n                .ant-tabs-tab-btn {\n                  color: @primary-color;\n                  font-size: 14px !important;\n                }\n              }\n              .ant-tabs-tab-btn {\n                color: @light-input;\n                font-size: 14px !important;\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n}\n\n.custom-table-grid-view-container {\n  table {\n    thead {\n      tr {\n        .ant-table-cell {\n          white-space: nowrap;\n        }\n      }\n    }\n    tbody {\n      tr {\n        td {\n          white-space: nowrap;\n        }\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
