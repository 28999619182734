import React from "react";

function ViewIcon() {
  return (
    <svg width="17" height="13" viewBox="0 0 17 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.375 6.75C5.375 4.83594 6.93359 3.25 8.875 3.25C10.7891 3.25 12.375 4.83594 12.375 6.75C12.375 8.69141 10.7891 10.25 8.875 10.25C6.93359 10.25 5.375 8.69141 5.375 6.75ZM8.875 8.9375C10.0781 8.9375 11.0625 7.98047 11.0625 6.75C11.0625 5.54688 10.0781 4.5625 8.875 4.5625C8.84766 4.5625 8.82031 4.5625 8.79297 4.5625C8.84766 4.72656 8.875 4.86328 8.875 5C8.875 5.98438 8.08203 6.75 7.125 6.75C6.96094 6.75 6.82422 6.75 6.6875 6.69531C6.6875 6.72266 6.6875 6.75 6.6875 6.75C6.6875 7.98047 7.64453 8.9375 8.875 8.9375ZM3.59766 2.83984C4.88281 1.63672 6.66016 0.625 8.875 0.625C11.0625 0.625 12.8398 1.63672 14.125 2.83984C15.4102 4.01562 16.2578 5.4375 16.668 6.42188C16.75 6.64062 16.75 6.88672 16.668 7.10547C16.2578 8.0625 15.4102 9.48438 14.125 10.6875C12.8398 11.8906 11.0625 12.875 8.875 12.875C6.66016 12.875 4.88281 11.8906 3.59766 10.6875C2.3125 9.48438 1.46484 8.0625 1.05469 7.10547C0.972656 6.88672 0.972656 6.64062 1.05469 6.42188C1.46484 5.4375 2.3125 4.01562 3.59766 2.83984ZM8.875 1.9375C7.07031 1.9375 5.62109 2.75781 4.5 3.79688C3.43359 4.78125 2.72266 5.92969 2.33984 6.75C2.72266 7.57031 3.43359 8.74609 4.5 9.73047C5.62109 10.7695 7.07031 11.5625 8.875 11.5625C10.6523 11.5625 12.1016 10.7695 13.2227 9.73047C14.2891 8.74609 15 7.57031 15.3828 6.75C15 5.92969 14.2891 4.78125 13.2227 3.79688C12.1016 2.75781 10.6523 1.9375 8.875 1.9375Z" fill="#606060" />
    </svg>

  );
}

export default ViewIcon;
