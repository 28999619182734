import React, { memo, useCallback, useContext } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Breadcrumb, Layout, Menu, Modal } from "antd";
import useBreadcrumbs from "use-react-router-breadcrumbs";
import CustomHeading from "components/UiComponents/CustomHeading";
import CustomRow from "components/UiComponents/CustomRow/index.jsx";
import CustomColumn from "components/UiComponents/CustomColumn/index.jsx";
import { BREADCRUMB_SEPARATOR, USER_TYPE } from "utils/Constants/Constant";
import {
  AllMenusItems,
  permissionsToRoutes,
  unAuthenticatedRoutes,
} from "utils/Constants/routes.constant";
import { useAuth } from "hooks/useAuth";
import LogoutIcon from "assets/SvgFiles/LogoutIcon";
import CircleExclamaion from "assets/SvgFiles/CircleExclamaion";
import { PageTitleContext } from "PageTitleContext";
import helperFunction from "utils/Helper/Functions.helper";
import authSlice from "../../redux/Slices/auth.Slice";
import userSlice from "../../redux/Slices/user.Slice";
import "./Header.less";

const { Header } = Layout;

const TopHeader = () => {
  const breadcrumbs = useBreadcrumbs();
  const { pageTitle } = useContext(PageTitleContext);
  const navigate = useNavigate();
  const location = useLocation();
  const { userObj } = useAuth();
  const { logout } = authSlice.actions;
  const { logoutMe } = userSlice.actions;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { confirm } = Modal;

  // Active menu key based on current location
  const activeMenuItemKey = AllMenusItems.find((item) =>
    location.pathname.startsWith(`/${item.linkTo}`)
  )?.selectedOptionKey;

  // Filter breadcrumbs
  const filteredBreadcrumbs = breadcrumbs.filter(({ match }) => {
    const segments = match.pathname.split("/");
    return (
      match.pathname !== unAuthenticatedRoutes.AUTHSCREEN &&
      !segments.some((segment) => /^\d+$/.test(segment))
    );
  });

  // Breadcrumb items
  const breadcrumbItems = filteredBreadcrumbs.map(
    ({ match, breadcrumb }, index) => {
      const isLastBreadcrumb = index === filteredBreadcrumbs.length - 1;
      return {
        key: match.pathname,
        title: isLastBreadcrumb ? (
          breadcrumb
        ) : (
          <Link to={match.pathname}>{breadcrumb}</Link>
        ),
      };
    }
  );

  // Filtering the menu items based on the permissions
  const filterMenuItemsByPermissions = (menuItems, permissions) => {
    if (!Array.isArray(permissions)) {
      return [];
    }
    return menuItems.filter((menuItem) => {
      const route = menuItem.linkTo;
      // Allow access if no requiredPermissions or an empty array
      const requiredPermissions = permissionsToRoutes[route] || [];
      if (requiredPermissions.length === 0) {
        return true;
      }
      return requiredPermissions.some((permission) =>
        permissions.includes(permission)
      );
    });
  };

  const userPermissions = userObj?.permissions || [];
  const filteredMenuItems = filterMenuItemsByPermissions(
    AllMenusItems,
    userPermissions
  );

  const menuItems = [
    ...filteredMenuItems.map((menu) => ({
      key: menu.selectedOptionKey,
      label: (
        <Link to={menu.linkTo}>
          {menu.icon && <span className="menu-icon">{menu.icon}</span>}
          <span>{menu.label}</span>
        </Link>
      ),
    })),
    {
      key: "logout",
      label: (
        <Link to="#" onClick={() => showPromiseConfirm()}>
          <LogoutIcon />
          <span>Logout</span>
        </Link>
      ),
    },
  ];

  const showPromiseConfirm = () => {
    confirm({
      wrapClassName: "logOutModal",
      title: `Are you sure?`,
      width: "455px",
      centered: "true",
      closable: "true",
      destroyOnClose: "true",
      content: t("areYouSureYouWantToLogout"),
      okText: t("logout"),
      cancelText: t("cancel"),
      icon: (
        <div className="icon-wrapper">
          <CircleExclamaion />
        </div>
      ),
      async onOk() {
        logoutCall();
      },
      async onCancel() {},
      footer: (_, { OkBtn, CancelBtn }) => (
        <>
          <OkBtn />
          <CancelBtn />
        </>
      ),
    });
  };

  const logoutCall = useCallback(async () => {
    helperFunction.deleteLocalStorage("jwtToken");
    helperFunction.deleteLocalStorage("isLoggedIn");
    // dispatch(clearCart());
    dispatch(logout());
    dispatch(logoutMe());

    navigate(`${unAuthenticatedRoutes.AUTHSCREEN}`);
  }, [dispatch, logout, logoutMe, navigate]);

  return (
    <Header className="headerWrapper">
      <CustomRow>
        <CustomColumn xs={24} sm={24} md={24} lg={12} xl={12}>
          <CustomHeading className="title">{pageTitle}</CustomHeading>

          {/* Render Breadcrumb only if there are more than one breadcrumb items */}
          {breadcrumbItems.length > 1 && (
            <Breadcrumb
              separator={BREADCRUMB_SEPARATOR}
              items={breadcrumbItems}
            />
          )}
        </CustomColumn>

        {/* <CustomColumn xs={24} sm={24} md={24} lg={12} xl={12}>
          {userObj?.userRole === USER_TYPE?.CUSTOMER && (
            <Menu
              mode="horizontal"
              selectedKeys={[activeMenuItemKey]}
              items={menuItems}
              className="top-header-menu"
            />
          )}
        </CustomColumn> */}
      </CustomRow>
    </Header>
  );
};

export default memo(TopHeader);
