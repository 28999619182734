import { Select } from "antd";
import React, { memo } from "react";
import "./CustomSelect.less";

const CustomSelect = (props) => {
  const { type, className } = props;
  let customClass = "";
  switch (type) {
    case "small":
      customClass = "small-dropdown";
      break;

    default:
      break;
  }
  return (
    <span className="kl-custom-select-container">
      <Select
        {...props}
        className={`${customClass} ${className}`}
        getPopupContainer={(trigger) => trigger.parentNode}
        dropdownStyle={{ position: "relative" }}
        allowClear
        showSearch
      />
    </span>
  );
};

export default memo(CustomSelect);
