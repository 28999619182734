// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.kl-custom-input-container {
  height: 45px;
  display: inline-block;
  width: 100%;
}
.kl-custom-input-container .ant-input-affix-wrapper,
.kl-custom-input-container .ant-spin-nested-loading {
  height: 100%;
}
.kl-custom-input-container .ant-input-affix-wrapper .ant-spin-container,
.kl-custom-input-container .ant-spin-nested-loading .ant-spin-container {
  height: 100%;
}
.kl-custom-input-container input {
  color: #000000;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  padding: 8px 12px 8px 12px;
  height: 100%;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0em;
}
.kl-custom-input-container input::placeholder {
  color: #00000080;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  font-weight: 400 !important;
  line-height: 1;
  opacity: 1;
}
`, "",{"version":3,"sources":["webpack://./src/components/UiComponents/CustomInput/CustomInput.less"],"names":[],"mappings":"AAEA;EACE,YAAA;EACA,qBAAA;EACA,WAAA;AADF;AAFA;;EAMI,YAAA;AAAJ;AANA;;EAQM,YAAA;AAEN;AAVA;EAYI,cAAA;EACA,eAAA;EACA,kCAAA;EACA,0BAAA;EACA,YAAA;EACA,gBAAA;EACA,cAAA;EACA,mBAAA;AACJ;AAAI;EACE,gBAAA;EACA,eAAA;EACA,kCAAA;EACA,2BAAA;EACA,cAAA;EACA,UAAA;AAEN","sourcesContent":["@import url(\"../../../assets/Styles/variables.less\");\n\n.kl-custom-input-container {\n  height: 45px;\n  display: inline-block;\n  width: 100%;\n  .ant-input-affix-wrapper,\n  .ant-spin-nested-loading {\n    height: 100%;\n    .ant-spin-container {\n      height: 100%;\n    }\n  }\n  input {\n    color: @black-color;\n    font-size: 14px;\n    font-family: @primary-font-family;\n    padding: 8px 12px 8px 12px;\n    height: 100%;\n    font-weight: 400;\n    line-height: 1;\n    letter-spacing: 0em;\n    &::placeholder {\n      color: @placeholder;\n      font-size: 14px;\n      font-family: @primary-font-family;\n      font-weight: 400 !important;\n      line-height: 1;\n      opacity: 1;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
